import styled, { css } from 'styled-components';
import { rem } from '../../../helpers/rem-converter';
import {
    desktop,
    desktopHide,
    desktopS,
    desktopXS,
    media,
    mediaHide,
    mobile,
    mobileHide,
    tablet,
    tabletHide,
} from '../../../styles/themes/media';
import Variables from '../../../styles/variables';
import { H1 } from '../../typography/H1';

export const Wrapper = styled.div<{ isApp: boolean }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: ${({ isApp }) => isApp ? rem(28) : rem(92)};
    margin-bottom: ${({ isApp }) => isApp ? rem(24) : rem(40)};

    ${media(desktopS, desktopXS)`
        margin-top: ${({ isApp }) => isApp ? rem(28) : rem(80)};
        margin-bottom: ${({ isApp }) => isApp ? rem(24) : rem(40)};
    `};

    ${tablet`
        margin-top: ${({ isApp }) => isApp ? rem(28) : rem(60)};
        margin-bottom: ${({ isApp }) => isApp ? rem(24) : rem(40)};
    `};

    ${mobile`
        margin-top: ${({ isApp }) => isApp ? rem(28) : rem(48)};
        margin-bottom: ${({ isApp }) => isApp ? rem(24) : rem(24)};
    `};
`;

export const Title = styled(H1)`
    font-family: ${Variables.fonts.fontWideMedium};

    margin: 0 auto;
    font-size: ${rem(32)};
    line-height: ${rem(36)};

    ${mobile`
        font-size: ${rem(24)};
        line-height: ${rem(28)};
    `};
`;

export const TitleContentDesktop = styled.div`
    ${mediaHide(tabletHide, mobileHide)};
`;

export const TitleContentMobile = styled.div`
    ${desktopHide};
`;

export const SearchContainer = styled.div<{ isApp: boolean }>`
    width: 100%;
    margin-top: ${rem(32)};
    font-size: ${rem(32)};
    line-height: ${rem(36)};

    ${desktop`width: ${rem(620)}`};
    ${tablet`margin-top: ${rem(32)}`};
    ${mobile`margin-top: ${rem(16)}`};
    ${media(desktop, tablet, mobile)`${({ isApp }) => isApp && css`margin-top: 0`}`};
`;
